<template>
  <div>
    <b-modal no-close-on-backdrop :id="id" size="lg" :title="`${this.$route.params.bookingId ? 'View/Edit' : 'Create'} Booking`" @hidden="closeModal">
      <b-row>
        <b-col cols="12" v-if="!loading && error">
          <b-alert variant="danger">{{ error }}</b-alert>
        </b-col>
        <b-col class="py-4 text-center" cols="12" v-if="loading">
          <b-spinner variant="primary"></b-spinner>
        </b-col>
      </b-row>
      <b-row v-if="!loading && !error">
        <b-col cols="12">
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Guest" :invalid-feedback="getValidationMessage('customer_id')" :state="!validationErrors.customer_id">
                <template v-if="user.type !== 'owner' || disableUpdate">
                  <customer-search :disabled="disableUpdate" :params="{ type: 'guest' }" v-model="editedBooking.customer_id" @search="customerSearch" />
                  <b-form-input :disabled="disableUpdate" class="mt-2" placeholder="Email Address" v-show="(!editedBooking.customer_id) && editedBooking.customer_search && (editedBooking.customer_search !== '')" v-model="editedBooking.customer_email" />
                </template>
                <b-card body-class="p-2" v-else>
                  <p class="text-muted mb-0">This booking is for myself/managed by myself.</p>
                </b-card>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Property" :invalid-feedback="getValidationMessage('property_id')" :state="!validationErrors.property_id">
                <property-search :disabled="disableUpdate" v-model="editedBooking.property_id" @selected="property => selectedProperty = property" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Start Date" :invalid-feedback="getValidationMessage('start_date')" :state="!validationErrors.start_date">
                <b-form-datepicker :disabled="disableUpdate" :date-disabled-fn="checkDateIsAvailable" v-model="editedBooking.start_date" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="End Date" :invalid-feedback="getValidationMessage('end_date')" :state="!validationErrors.end_date">
                <b-form-datepicker :disabled="disableUpdate" :date-disabled-fn="checkDateIsAvailable" v-model="editedBooking.end_date" />
              </b-form-group>
            </b-col>
            <b-col cols="12" v-if="numOfNights">
              <p class="p-0">{{ numOfNights }} nights @ {{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format((editedBooking.amount_client / numOfNights)) }} per night</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Booking Amount" :invalid-feedback="getValidationMessage('amount')" :state="!validationErrors.amount">
                <b-input-group prepend="£">
                  <b-form-input :disabled="disableUpdate" type="number" min="0" step="0.01" v-model="editedBooking.amount" />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" v-if="user.type === 'staff'">
              <b-form-group label="Owner Amount" :invalid-feedback="getValidationMessage('amount_client')" :state="!validationErrors.amount_client">
                <b-input-group prepend="£">
                  <b-form-input :disabled="disableUpdate" type="number" min="0" step="0.01" v-model="editedBooking.amount_client" />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" :md="user.type !== 'staff' ? 6 : 12">
              <b-form-group label="Number of Guests" :invalid-feedback="getValidationMessage('guests')" :state="!validationErrors.guests">
                <b-form-input :disabled="disableUpdate" type="number" min="0" step="1" v-model="editedBooking.guests" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="user.type === 'staff'">
            <b-col cols="12" md="6">
              <b-form-group label="Platform Fees" :invalid-feedback="getValidationMessage('platform_fees')" :state="!validationErrors.platform_fees">
                <b-input-group prepend="£">
                  <b-form-input :disabled="disableUpdate" type="number" min="0" step="0.01" v-model="editedBooking.platform_fees" />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Payment Gateway Fees" :invalid-feedback="getValidationMessage('gateway_fees')" :state="!validationErrors.gateway_fees">
                <b-input-group prepend="£">
                  <b-form-input :disabled="disableUpdate" type="number" min="0" step="0.01" v-model="editedBooking.gateway_fees" />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Notes" :invalid-feedback="getValidationMessage('notes')" :state="!validationErrors.notes">
                <b-form-textarea :disabled="disableUpdate" type="string" v-model="editedBooking.notes" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Cancellation Reason" :invalid-feedback="getValidationMessage('cancellation_reason')" :state="!validationErrors.cancellation_reason" v-if="editedBooking.deleted_at">
                <b-form-textarea :disabled="disableUpdate" type="string" v-model="editedBooking.cancellation_reason" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-if="!editedBooking.id">
        <b-col cols="12">
          <p class="h5">Initial Payment</p>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Amount" :invalid-message="getValidationMessage('initial_payment_amount')" :state="validationErrors.initial_payment_amount">
            <b-input-group prepend="£">
              <b-form-input id="create_booking_initial_payment_amount" v-model="editedBooking.initial_payment_amount" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Reference" :invalid-message="getValidationMessage('initial_payment_ref')" label-for="create_booking_initial_payment_ref" :state="validationErrors.initial_payment_ref">
            <b-form-input id="create_booking_initial_payment_ref" v-model="editedBooking.initial_payment_ref" />
          </b-form-group>
        </b-col>
      </b-row>
      <edit-booking-payments @update:data="load()" v-if="editedBooking.id" />
      <template #modal-footer>
        <b-button
          @click.prevent="deleteBooking"
          variant="danger"
          class="mr-auto"
          v-if="editedBooking?.id && (user.type == 'owner' && !disableUpdate)"
        >Delete</b-button>

        <b-form-checkbox class="mr-4" disabled v-model="editedBooking.paid">Paid in Full</b-form-checkbox>
        <b-button @click.prevent="closeModal" :variant="disableUpdate ? 'primary' : 'light'">Close</b-button>
        <b-button @click.prevent="saveBooking" variant="primary" v-if="!disableUpdate">
          <b-spinner class="mx-4" variant="light" small v-if="saving"/>
          <span v-if="!saving">{{ $route.params.bookingId ? 'Update' : 'Create' }}</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapState } from 'vuex'
import EditBookingPayments from './EditBookingPayments.vue'
import CustomerSearch from '../../components/CustomerSearch.vue'
import PropertySearch from '../../components/PropertySearch.vue'
import handleErrors from '../../mixins/handleErrors'

export default {
  components: { CustomerSearch, EditBookingPayments, PropertySearch },
  mixins: [handleErrors],
  data() {
    return {
      validationErrors: {},
      genericError: undefined,
      selectedProperty: undefined,
      editedBooking: {
        customer_id: null,
        customer_search: null,
        customer_email: null,
        property_id: 0,
        end_date: null,
        start_date: null,
        paid: false,
        amount: 0,
        guests: 1,
        gateway_fees: 0,
        amount_client: 0,
        platform_fees: 0,
        notes: '',
        cancellation_reason: '',
        initial_payment_amount: 0,
        initial_payment_ref: ''
      },
      loading: false,
      saving: false,
      error: ''
    };
  },
  emits: ['hidden', 'updated'],
  props: {
    booking: Object,
    id: String,
  },
  async mounted() {
    await this.load()
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('bookings', ['errors']),
    disableUpdate() {
      return this.user.type === 'owner'
        && this.editedBooking.customer_id !== null
        && this.editedBooking.id !== undefined;
    },
    numOfNights() {
      if(this.editedBooking.start_date && this.editedBooking.end_date){
        return Math.round(moment.duration(moment(this.editedBooking.end_date).startOf('day').diff(moment(this.editedBooking.start_date).startOf('day'))).asDays());
      }

      return null;
    }
  },
  methods: {
    ...mapActions('bookings', ['fetch', 'fetchSingle', 'updateOrCreate', 'delete']),
    closeModal() {
      this.editedBooking = {
        customer_id: null,
        property_id: 0,
        end_date: null,
        start_date: null,
        paid: false,
        amount: 0,
        guests: 1,
        gateway_fees: 0,
        amount_client: 0,
        platform_fees: 0,
        notes: '',
        cancellation_reason: '',
        initial_payment_amount: 0,
        initial_payment_ref: ''
      };
      this.validationErrors = {};
      if (this.$route.name !== this.$route.meta.parentName) {
        this.$router.push({ name: this.$route.meta.parentName });
      }
      this.$emit('hidden')
    },
    async load() {
      if (this.$route.params.bookingId && this.booking) {
        this.loading = true;

        try {
          const booking = await this.fetchSingle(this.$route.params.bookingId);
          this.editedBooking = {
            ...booking,
          };

          this.selectedProperty = booking.property;
          this.loading = false;
        } catch (err) {
          this.error = "Can not load booking. Please try again later.";
          this.loading = false;
        }
      }
    },
    saveBooking() {
      this.saving = true;
      this.updateOrCreate(this.editedBooking).then(() => {
        this.saving = false;
        this.genericError = undefined;
        this.closeModal();
        this.$emit('updated');
      }).catch(err => {
        if (err.response && err.response.status === 422) {
          this.saving = false;
          this.handleValidationErrors(err);
        } else {
          this.saving = false;
          this.genericError = 'There was an error saving just now. Please try again later.';
        }
      });
    },
    customerSearch(text) {
      this.editedBooking.customer_search = text;
    },
    checkDateIsAvailable(ymd) {
      let cDate = moment(ymd);

      if (!this?.selectedProperty) return false;

      for (const blockedDate of this?.selectedProperty?.futureAvailability) {
        let bDate = moment(blockedDate);

        if (bDate.isSame(cDate)) return true;
      }

      return false;
    },
    deleteBooking() {
      this.delete(this.editedBooking.id).then(() => this.load());

      this.closeModal();
    },
  },
  watch: {
    booking(value) {
      this.editedBooking = {
        ...value,
      };
    }
  }
}
</script>

<style>

</style>
