<template>
  <div class="d-flex flex-column overflow-auto vh-100">
    <edit-booking @hidden="selectedBooking = undefined" @updated="load" id="edit-booking" :booking="selectedBooking" />
    <sticky-header :title="showCal ? `Bookings - ${calendarDateTitle}` : 'Bookings'">
      <template #buttons>
        <div class="cal-action-buttons d-flex">
          <b-form-checkbox @input="load" class="align-self-center mr-2" v-model="showCal">Show Calendar</b-form-checkbox>
          <b-button v-if="showCal" class="ml-2" @click.prevent="calPrev" variant="outline-primary"><fa-icon icon="angle-left"/></b-button>
          <b-button v-if="showCal" class="ml-2" @click.prevent="calToday" variant="outline-primary">Today</b-button>
          <b-button v-if="showCal" class="ml-2" @click.prevent="calNext" variant="outline-primary"><fa-icon icon="angle-right"/></b-button>
          <b-button class="ml-2" id="legend" variant="primary" v-show="showCal"><fa-icon class="mr-2" icon="users" /> Legend</b-button>
          <b-button class="ml-2" variant="primary" :to="{name: 'bookings.create'}"><fa-icon class="mr-2" icon="plus" />Create</b-button>
        </div>
      </template>
    </sticky-header>
    <b-popover target="legend" triggers="click" placement="bottomleft">
      <div class="px-4 py-3">
        <p class="font-weight-bold h5 mb-2 pr-5">Platform Colors</p>
        <p class="h4 mb-1 w-100">
          <span class="badge w-100" style="background-color: #1A1A1A; color: white;">CRM</span>
        </p>
        <p class="h4 mb-1 w-100">
          <span class="badge w-100" style="background-color: #FF5A5F; color: white;">Airbnb</span>
        </p>
        <p class="h4 mb-1 w-100">
          <span class="badge w-100" style="background-color: #003580; color: white;">Booking.com</span>
        </p>
        <p class="h4 mb-1 w-100">
          <span class="badge w-100" style="background-color: #E86024; color: white;">VRBO</span>
        </p>
        <p class="h4 mb-1 w-100">
          <span class="badge w-100" style="background-color: #a914eb; color: white;">Other</span>
        </p>
      </div>
    </b-popover>
    <div class="d-flex flex-column container-fluid flex-fill p-3">
      <div class="flex-fill" v-if="showCal">
        <div class="d-flex flex-column mt-5 py-3 justify-content-center" v-if="loading || deleting">
          <b-spinner class="align-self-center" :variant="deleting ? 'danger' : 'primary'" />
          <p class="align-self-center font-weight-bold h5 mt-3 mb-0">{{ deleting ? 'Deleting' : 'Loading' }}...</p>
        </div>
        <full-calendar ref="calendar" :options="calendarOptions" v-if="!loading && !deleting" />
      </div>
      <div class="flex-fill" v-if="!showCal">
        <b-row class="mb-2">
          <b-col cols="12" md="9">
            <b-form-input debounce="500" @update="load(true)" placeholder="Search" type="text" v-model="query" />
          </b-col>
          <b-col cols="12" md="3">
            <b-button block @click="filtersShown = !filtersShown" variant="dark">Filters <fa-icon class="ml-2" icon="chevron-down" v-if="!filtersShown" /><fa-icon class="ml-2" icon="chevron-up" v-else /></b-button>
          </b-col>
        </b-row>
        <b-card class="mb-2" v-if="filtersShown">
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group>
                <template #label>
                  <b-checkbox v-model="ownerEnabled">Owner</b-checkbox>
                </template>
                <user-search :disabled="!ownerEnabled" type="client" v-model="selectedOwner" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group>
                <template #label>
                  <b-checkbox v-model="propertyEnabled">Property</b-checkbox>
                </template>
                <property-search :disabled="!propertyEnabled" v-model="selectedProperty" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Start Date">
                <b-form-datepicker v-model="startDate" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" locale="en-gb" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="End Date">
                <b-form-datepicker v-model="endDate" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" locale="en-gb" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-row class="mb-2" v-if="!showCal">
          <b-col cols="12">
            <b-button @click="changeListView('this_month')" :variant="(listView == 'this_month') ? 'primary' : 'outline-primary'">This Month</b-button>
            <b-button @click="changeListView('upcoming')" class="ml-2" :variant="(listView == 'upcoming') ? 'primary' : 'outline-primary'">Upcoming</b-button>
            <b-button @click="changeListView('complete')" class="ml-2" :variant="(listView == 'complete') ? 'primary' : 'outline-primary'">Completed</b-button>
            <b-button @click="changeListView('cancelled')" class="ml-2" :variant="(listView == 'cancelled') ? 'primary' : 'outline-primary'">Cancelled</b-button>
            <b-button @click="changeListView('all')" class="ml-2" :variant="(listView == 'all') ? 'primary' : 'outline-primary'">All</b-button>
          </b-col>
        </b-row>

        <b-card class="text-center py-5" v-if="!loading && data.length === 0">
          <p class="h3 font-weight-bold">No bookings found.</p>
          <p class="text-muted mb-0">There are no bookings for this selected date period, or matching your query/filters.</p>
        </b-card>

        <b-list-group class="mb-4" v-if="!loading && !deleting">
          <b-table
            sortable responsive outlined
            :items="formattedData" :fields="['status', {key: 'guests', sortable: true}, {key: 'check_in', sortable: true}, {key: 'check_out', sortable: true}, {key: 'booked', sortable: true}, 'nights', {key: 'location', sortable: true}, 'actions']"
          >
            <template #cell(check_in)="row">
              {{ moment(row.item.check_in).format('D MMM YYYY') }}
            </template>
            <template #cell(check_out)="row">
              {{ moment(row.item.check_out).format('D MMM YYYY') }}
            </template>
            <template #cell(booked)="row">
              {{ moment(row.item.booked).format('D MMM YYYY') }}
            </template>
            <template #cell(actions)="row">
              <div class="align-self-center d-flex flex-md-row flex-column">
                <b-button class="mb-1 mb-md-0 ml-md-1" @click.prevent="edit(row.item.id)" variant="primary" v-b-popover.top.hover="`Edit`"><fa-icon icon="pen" /></b-button>
                <b-button class="mb-1 mb-md-0 ml-md-1" @click.prevent="del(row.item.id)" v-show="canCancelBooking(row)" variant="danger" v-b-popover.top.hover="`Cancel`"><fa-icon icon="times" /></b-button>
              </div>
            </template>
          </b-table>

          <b-pagination-nav :link-gen="paginationLinkGen" :number-of-pages="lastPage" v-model="pageNo" use-router />
        </b-list-group>
        <div class="d-flex flex-column mt-5 py-3 justify-content-center" v-if="loading || deleting">
        <b-spinner class="align-self-center" :variant="deleting ? 'danger' : 'primary'" />
        <p class="align-self-center font-weight-bold h5 mt-3 mb-0">{{ deleting ? 'Deleting' : 'Loading' }}...</p>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import map from 'lodash/map'
import filter from 'lodash/filter'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import FullCalendar from '@fullcalendar/vue'
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from '@fullcalendar/daygrid'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import EditBooking from './Modals/EditBooking.vue'
import PropertySearch from '../components/PropertySearch.vue'
import StickyHeader from '../components/StickyHeader.vue'
import UserSearch from '../components/UserSearch.vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: { EditBooking, FullCalendar, PropertySearch, StickyHeader, UserSearch },
  data() {
    const self = this;
    if (this.$route.query.page) {
      sessionStorage.setItem("booking:pageNo", this.$route.query.page);
    }

    return {
      showCal: (sessionStorage.getItem("booking:showCal") == 'true') ?? 0,
      listView: sessionStorage.getItem("booking:listView") ?? 'this_month',
      query: sessionStorage.getItem("booking:query") ?? '',
      pageNo: sessionStorage.getItem("booking:pageNo") ?? 1,
      selectedBooking: {},
      endDate: sessionStorage.getItem("booking:endDate") ? moment(sessionStorage.getItem("booking:endDate")).toDate() : moment().endOf('month').toDate(),
      startDate: sessionStorage.getItem("booking:startDate") ? moment(sessionStorage.getItem("booking:startDate")).toDate() : moment().startOf('month').toDate(),
      calendarDateTitle: 'Loading',
      calendarOptions: {
        // Plugins, Themes and View
        plugins: [ bootstrapPlugin, dayGridPlugin, timeGridPlugin ],
        initialView: 'dayGridMonth',
        themeSystem: 'bootstrap',
        headerToolbar: false,
        weekends: true,
        height: "100%",

        // Events
        eventClick: function(info) {
          self.edit(info.event.id);
        },
        events: [],
      },

      filtersShown: sessionStorage.getItem("booking:filtersShown") ?? false,
      ownerEnabled: false,
      selectedOwner: sessionStorage.getItem("booking:selectedOwner") ?? undefined,
      propertyEnabled: false,
      selectedProperty: sessionStorage.getItem("booking:selectedProperty") ?? undefined,
      formattedData: [],

      columnFormat: {
            month: 'ddd',
            week: 'ddd d/M',
            day: 'dddd d/M'
      },
    };
  },
  async mounted() {
    if(this.user.type === 'owner'){
      this.showCal = true;
    }

    if (this.$route.meta.modalId) {
      this.$bvModal.show(this.$route.meta.modalId)
    }

    await this.load();
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('bookings', ['data', 'deleting', 'loading', 'lastPage']),
  },
  methods: {
    ...mapActions('bookings', ['fetch', 'delete', 'updateOrCreate']),
    moment,
    calNext() {
      this.date = (this.date ?? moment()).add(1, 'months');
      this.startDate = moment(this.date).startOf('month')
      this.endDate = moment(this.date).endOf('month')
      this.load();
    },
    calPrev() {
      this.date = (this.date ?? moment()).add(-1, 'months');
      this.startDate = this.date.clone().startOf('month')
      this.endDate = this.date.clone().endOf('month')
      this.load();
    },
    calToday() {
      this.date = moment();
      this.startDate = moment(this.date).startOf('month')
      this.endDate = moment(this.date).endOf('month')
      this.load();
    },
    async load(reset = false) {
      if (reset) {
        sessionStorage.removeItem("booking:pageNo");
        this.pageNo = 1;
      }
      return this.fetch(Object.fromEntries(Object.entries({
        cal: this.showCal ? 1 : 0,
        block_future_availability: 1,
        cancelled: (this.listView == 'cancelled') ? 1 : undefined,
        query: this.query,
        page: this.pageNo ?? 1,
        perPage: 50,
        owner_id: this.ownerEnabled ? this.selectedOwner : undefined,
        property_id: this.propertyEnabled ? this.selectedProperty : undefined,
        start_date: moment(this.startDate).format('YYYY-MM-DD'),
        end_date: moment(this.endDate).format('YYYY-MM-DD')
      }).filter(([, v]) => v !== undefined))).then(() => {
        this.calendarDateTitle = (this.date ?? moment()).format('MMMM YYYY');
      }).then(() => {
        this.calendarDateTitle = (this.date ?? moment()).format('MMMM YYYY');
        if (this.$refs.calendar) {
          this.$refs.calendar.getApi().gotoDate((this?.date ?? moment()).toDate());
        }
      });
    },
    async changeListView(view) {
      this.listView = view;
      sessionStorage.setItem("booking:listView", view);

      if (view == 'this_month') {
        this.startDate = moment().startOf('month')
        this.endDate = moment().endOf('month')
      } else if (view == 'upcoming') {
        this.startDate = moment().add(1, 'day')
        this.endDate = moment().add(1, 'year')
      } else if (view == 'complete') {
        this.startDate = moment().add(-1, 'year')
        this.endDate = moment()
      } else if (view == 'cancelled') {
        this.startDate = moment().add(-1, 'year')
        this.endDate = moment().add(1, 'year')
      } else if (view == 'all') {
        this.startDate = moment().add(-1, 'year')
        this.endDate = moment().add(1, 'year')
      }

      //this.load();
    },
    paginationLinkGen(pageNo) {
      return pageNo === 1
        ? {name: 'bookings', query: pickBy({...this.$route.params, page: null}, identity)}
        : {name: 'bookings', query: {...this.$route.params, page: pageNo}};
    },
    pluralise(count, term) {
      if (count === 1) return term;
      return `${term}s`;
    },
    edit(id) {
      this.selectedBooking = this.data.find(c => c.id == id);

      if (!(this.$route.name === 'bookings.update' && parseInt(this.$route.params.bookingId) === id)) {
        this.$router.push({ name: 'bookings.update', params: { bookingId: id } });
      }
    },
    del(id) {
      let booking = this.data.find(c => c.id === id);

      let cancellationReason
      let bookingAmount = booking.amount;
      let ownerAmount = booking.amount_client;
      let platformFees = booking.platform_fees
      let platformGatewayFees = booking.gateway_fees

      this.$bvModal.msgBoxConfirm(
        <div>
          <b-row>
            <b-col cols="12">
              <b-form-group label='Cancellation Reason'>
                <b-form-input type="string" vModel={cancellationReason}/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group label='Booking Amount'>
                <b-input-group prepend="£">
                  <b-form-input type="string" vModel={bookingAmount}/>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label='Owner Amount'>
                <b-input-group prepend="£">
                  <b-form-input type="string" vModel={ownerAmount}/>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group label='Platform Fees'>
                <b-input-group prepend="£">
                  <b-form-input type="string" vModel={platformFees}/>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label='Platform Gateway Fees'>
                <b-input-group prepend="£">
                  <b-form-input type="string" vModel={platformGatewayFees}/>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        , {
          title: 'Cancel',
          okText: 'Yes, Cancel',
          okVariant: 'danger',
        cancelText: 'No',
        cancelVariant: 'light',
        centered: true
      }).then(shouldDelete => {
        if (!shouldDelete) {
          return;
        }

        booking.amount = bookingAmount;
        booking.amount_client = ownerAmount;
        booking.platform_fees = platformFees;
        booking.gateway_fees = platformGatewayFees;
        booking.cancellation_reason = cancellationReason;

        return this.updateOrCreate(booking).then(
          () => this.delete(id),
          () => this.load()
        );
      });
    },
    canCancelBooking(item) {
      return this.user.type === 'staff' || (this.user.type === 'owner' && moment().isBefore(item.start_date) && (item.customer_id === null))
    }
  },
  watch: {
    filtersShown(data) {
      sessionStorage.setItem("booking:filtersShown", data)
    },
    async endDate(data) {
      sessionStorage.setItem("booking:endDate", data)
      await this.load();
    },
    async startDate(data) {
      sessionStorage.setItem("booking:startDate", data)
      await this.load();
    },
    async ownerEnabled(data) {
      sessionStorage.setItem("booking:ownerEnabled", data)
      await this.load();
    },
    async selectedOwner(data) {
      sessionStorage.setItem("booking:selectedOwner", data)
      await this.load();
    },
    async propertyEnabled(data) {
      sessionStorage.setItem("booking:propertyEnabled", data)
      await this.load();
    },
    async selectedProperty(data) {
      sessionStorage.setItem("booking:selectedProperty", data)
      await this.load();
    },
    async '$route'(newValue, value) {
      if (!value.meta.modalId && newValue.meta.modalId) {
        return this.$bvModal.show(newValue.meta.modalId);
      }

      /*
      if (newValue.name === 'bookings' || (newValue.name === value.name && newValue.query.page !== value.query.page)) {
        await this.load();
      }
       */

      return this.$bvModal.hide(value.meta.modalId);
    },
    data(data) {
      this.calendarOptions = {
        ...this.calendarOptions,
        events: map(data, booking => ({
          id: booking.id,
          title: `Booking at ${booking.property && booking.property.name} - ${booking?.customer?.name ?? 'Owner Booking'}`,
          start: booking.start_date,
          end: booking.end_date,
          allDay: false,

          // Display
          backgroundColor: function(booking) {
            if (booking.platform === 'Airbnb') {
              return '#FF5A5F';
            } else if (booking.platform === 'Booking.com') {
              return '#003580';
            } else if (booking.platform === 'VRBO') {
              return '#E86024';
            } else if (booking.platform == null) {
              return '#1A1A1A';
            } else {
              return '#a914eb';
            }
          }(booking),
          borderColor: '#1A1A1A',
          textColor: '#FFFFFF',
        })),
      };

      this.formattedData = filter(data, booking => {
        if (
          (this.listView == 'upcoming') &&
          (moment(booking.start_date).isBefore(moment()))
        ) return false;

        if (
          (this.listView == 'complete') &&
          (moment(booking.end_date).isAfter(moment()))
        ) return false;

        return true;
      }).map(booking => {
        return {
          id: booking.id,
          status: booking.deleted_at ? 'Cancelled' : (moment().isBetween(moment(booking.start_date), moment(booking.end_date)) ? 'Currently Hosting' : (moment().isBefore(moment(booking.start_date)) ? ('Future Booking') : 'Past Booking')),
          guests: (booking?.customer?.name ?? 'Owner Booking') + (booking.guests ? " - " + booking.guests + " Guests" : ''),
          check_in: booking.start_date, //moment(booking.start_date).format('D MMM YYYY'),
          check_out: booking.end_date, //moment(booking.end_date).format('D MMM YYYY'),
          booked: booking.created_at, //moment(booking.created_at).format('D MMM YYYY'),
          nights: Math.floor(moment(booking.end_date).diff(moment(booking.start_date), 'days', true)) + 1,
          location: booking.property.name,
          user: booking.user
        }
      })
    },
    query(data) {
      sessionStorage.setItem("booking:query", data);
    },
    pageNo(data) {
      sessionStorage.setItem("booking:pageNo", data);
      this.load();
    },
    showCal(data) {
      sessionStorage.setItem("booking:showCal", data);
    }
  }
}
</script>

<style>
.cal-action-buttons {
  flex-direction: row;
}

@media (max-width: 500px) {
  .cal-action-buttons {
    flex-wrap: wrap;
  }
}
</style>
