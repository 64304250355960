<template>
  <b-row class="border-top mt-4 pt-4" v-if="user.type === 'staff'">
    <b-col class="align-items-center d-flex flex-row justify-content-center" cols="12" v-if="loading">
      <b-spinner />
    </b-col>
    <b-col cols="12" v-if="!loading">
      <div class="align-items-center d-flex flex-row mb-3">
        <p class="h5">Booking Payments</p>
        <div class="flex-fill"></div>
        <p class="mb-0 mr-2 text-muted">{{ (new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' })).format(payments.reduce((a, p) => a + parseInt(p.amount), 0)) }} paid of {{ (new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' })).format(booking.amount) }} total</p>
        <b-button @click.passive="generateInvoice" variant="dark">View Invoice</b-button>
      </div>
      <b-card class="mb-4" :key="payment.id" v-for="payment in payments">
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group label="Name" :label-for="`update_booking_payment_${payment.id}_name`">
              <b-form-input :id="`update_booking_payment_${payment.id}_name`" v-model="payment.name" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Amount" :label-for="`update_booking_payment_${payment.id}_amount`">
              <b-input-group prepend="£">
                <b-form-input :id="`update_booking_payment_${payment.id}_amount`" v-model="payment.amount" />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Paid On" :label-for="`update_booking_payment_${payment.id}_paid_at`">
              <b-form-datepicker :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric', weekday: undefined }" :id="`update_booking_payment_${payment.id}_paid_at`" v-model="payment.paid_at" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Payment Generator" :label-for="`update_booking_payment_${payment.id}_generator`">
              <b-form-select :id="`update_booking_payment_${payment.id}_generator`" :options="generatorOptions" v-model="payment.generator" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Reference" :label-for="`update_booking_payment_${payment.id}_ref`">
              <b-form-input :id="`update_booking_${payment.id}_ref`" v-model="payment.meta.ref" />
            </b-form-group>
          </b-col>
          <b-col class="align-items-end d-flex flex-row justify-content-end" cols="12" md="4">
            <div class="d-flex flex-col align-items-center">
              <p class="mb-3 mr-2 text-muted" v-if="payment.logged_by"><small>Logged by {{ payment.logged_by.name }}</small></p>
              <b-button class="mb-3" @click.passive="updatePayment(payment)" variant="primary"><b-spinner class="mx-3" small variant="light" v-if="saving === payment.id" /><span v-else>Update</span></b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group label="Name" :invalid-message="getValidationMessage('name')" label-for="create_booking_payment_name" :state="validationErrors.name">
              <b-form-input id="create_booking_payment_name" v-model="paymentToCreate.name" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Amount" :invalid-message="getValidationMessage('amount')" label-for="create_booking_payment_amount" :state="validationErrors.amount">
              <b-input-group prepend="£">
                <b-form-input id="create_booking_payment_amount" v-model="paymentToCreate.amount" />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Paid On" :invalid-message="getValidationMessage('paid_at')" label-for="create_booking_payment_paid_at" :state="validationErrors.paid_at">
              <b-form-datepicker :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric', weekday: undefined }" id="create_booking_payment_paid_at" v-model="paymentToCreate.paid_at" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Payment Generator" :invalid-message="getValidationMessage('generator')" label-for="create_booking_generator" :state="validationErrors.generator">
              <b-form-select id="create_booking_payment_generator" :options="generatorOptions" v-model="paymentToCreate.generator" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Reference" :invalid-message="getValidationMessage('ref')" label-for="create_booking_ref" :state="validationErrors.ref">
              <b-form-input id="create_booking_ref" v-model="paymentToCreate.ref" />
            </b-form-group>
          </b-col>
          <b-col class="align-items-end d-flex flex-row justify-content-end" cols="12" md="4">
            <b-button class="mb-3" @click.passive="createPayment" variant="primary"><b-spinner class="mx-5" small variant="light" v-if="saving === 0" /><span v-else>Create Payment</span></b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import handleErrors from '../../mixins/handleErrors'

export default {
  computed: {
    ...mapState('auth', ['user']),
  },
  data() {
    return {
      generatorOptions: [
        { text: 'Airbnb', value: 'airbnb' },
        { text: 'Booking.com', value: 'booking.com' },
        { text: 'Website (Stripe)', value: 'website' },
        { text: 'Invoice', value: 'invoice' },
      ],
      genericError: '',
      loading: false,
      saving: -1,
      payments: [],
      paymentToCreate: {
        name: '',
        amount: 0,
        paid_at: new Date(),
        generator: 'invoice',
        ref: '',
      },
    }
  },
  emits: ['update:data'],
  mixins: [handleErrors],
  methods: {
    ...mapActions('bookingPayments', ['fetch', 'updateOrCreate']),
    ...mapActions('bookings', { fetchBookings: 'fetchSingle' }),
    async updateOrCreatePayment(id, name, amount, paid_at, ref, generator) {
      try {
        this.saving = id || 0;
        const { data } = await this.updateOrCreate({
          id, name, amount, paid_at, ref, generator,
          booking_id: this.$route.params.bookingId,
          logged_by_id: this.user.id,
        })
        if (!id) {
          this.payments = [...this.payments, data]
          this.paymentToCreate = {
            name: '',
            amount: 0,
            paid_at: new Date(),
            generator: 'invoice',
            ref: '',
          }
        }
        this.$emit('update:data')
        this.saving = -1;
      } catch (err) {
        if (err.response && err.response.status === 422) {
          this.saving = -1;
          await this.handleValidationErrors(err);
        } else {
          this.saving = -1;
          this.genericError = 'There was an error saving just now. Please try again later.';
        }
      }
    },
    async fetchPayments() {
      this.loading = true;
      const { data } = await this.fetch({ booking_id: this.$route.params.bookingId })
      this.payments = [ ...data.map(p => ({ ...p, generator: p.generated_by })) ];
      this.loading = false;
    },
    async createPayment() {
      await this.updateOrCreatePayment(
        undefined,
        this.paymentToCreate.name,
        this.paymentToCreate.amount,
        this.paymentToCreate.paid_at,
        this.paymentToCreate.ref,
        this.paymentToCreate.generator,
      )
    },
    generateInvoice() {
      window.open(`/api/bookings/${this.$route.params.bookingId}/invoice`, '_blank')
    },
    async updatePayment(payment) {
      await this.updateOrCreatePayment(
        payment.id,
        payment.name,
        payment.amount,
        payment.paid_at,
        payment?.meta?.ref,
        payment.generator,
      )
    }
  },
  async mounted() {
    this.booking = await this.fetchBookings(this.$route.params.bookingId)
    await this.fetchPayments()
  }
}
</script>
